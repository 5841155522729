<template>
  <div>
    <v-stepper class="custom-header pa-0 ma-0" v-model="e1">
      <div style="height: 20vh !important">
        <v-btn icon @click="e1 === 1 ? $emit('closePaymentDrawer') : close()">
          <v-icon color="primary">{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-divider class="mb-2" style="border-width: 1px;" />
        <div class="d-flex justify-center align-center">
          <div class="d-flex align-center">
            <slot name="image"></slot>
            {{ title }}
          </div>
        </div>
        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :key="`${title}-step-${index}`"
              :complete="e1 > index + 1"
              :step="index + 1"
              style="color: #2bd4aa !important"
              :class="{
                'text--white': true,
                'current-step': e1 === index + 1,
                'completed-step': e1 > index + 1,
              }"
            >
            </v-stepper-step>
            <v-divider
              v-if="index < steps.length - 1"
              :key="index"
              :class="{ 'active-divider': e1 > index + 1 }"
            ></v-divider>
          </template>
        </v-stepper-header>
      </div>
      <v-stepper-items style="min-height: 80vh !important">
        <v-stepper-content
          v-for="(step, index) in steps"
          :key="index"
          :step="index + 1"
          style="padding-bottom: 0"
        >
          <v-card>
            <v-card-text class="card-text">
              <component
                class="component"
                :is="step.component"
                :data="step.data"
                :current-step="e1"
                :total-steps="steps.length"
                @next="handleNext"
                @prev="prevStep"
                @close="close"
                @setStep="setStep"
                @closePaymentDrawer="$emit('closePaymentDrawer')"
              />
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mdiInformationOutline, mdiClose } from "@mdi/js";

export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiClose,
      },
      e1: 1,
    };
  },
  methods: {
    nextStep() {
      if (this.e1 < this.steps.length) {
        this.e1++;
      }
    },
    prevStep() {
      if (this.e1 > 1) {
        this.e1--;
      }
    },
    close() {
      console.log(this.e1, this.steps.length);
      if(this.e1 === this.steps.length){
        this.$emit("refresh");
      }
      
      this.resetStepper();
      this.$emit("close");
    },
    setStep(step) {
      this.e1 = step;
    },
    handleNext(data) {
      this.$emit("next", data);
      this.nextStep();
    },
    resetStepper() {
      this.e1 = 1;
    },
  },
};
</script>

<style>
.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: white !important;
}
.v-application .primary {
  background-color: #2bd4aa !important;
}
</style>

<style scoped>
.card-text {
  display: flex;
  flex-direction: column;
  min-height: 75vh !important;
}

.component {
  flex-grow: 1;
  min-height: 100% !important;
}
</style>
