// copyToClipboardMixin.js
export const copyTextToClipboard = {
  methods: {
    async copyTextToClipboard(payloadText) {
      try {
        if (!navigator.clipboard) {
          throw new Error("Clipboard API not available.");
        }
        await navigator.clipboard.writeText(payloadText);
        console.log("Text copied to clipboard");
        this.$dialog.notify.success(this.$t("Text copied"));
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    },
  },
};
