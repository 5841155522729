<template>
  <div class="d-flex flex-column justify-between">
    <v-text-field
      :label="$t('Amount to add')"
      v-model="data.amount"
      v-money="money"
      prefix="$"
      outlined
      dense
      class="pa-2"
      validate-on-blur
      :rules="[
        (v) => !!v || $t('Amount is required'),
        (v) => {
          const numericValue =
            typeof v === 'number'
              ? v
              : parseFloat(this.data.amount.replace(/,/g, ''));
          return numericValue > 0 || $t('Amount must be greater than 0');
        },
      ]"
    />

    <!-- Botones para volver y continuar -->
    <div class="button-group">
      <v-btn
        rounded
        outlined
        style="width: 40%"
        @click="$emit('closePaymentDrawer')"
      >
        {{ $t("Back") }}
      </v-btn>
      <v-btn
        rounded
        style="width: 40%"
        color="primary"
        :disabled="handleDisabled"
        @click="handleNext"
      >
        {{ $t("Continue") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import DatePickerInput from "@/components/DatePickerInput";

export default {
  name: "AmountStep",
  components: {
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
    };
  },
  methods: {
    formatCurrency() {
      // Formatear el valor como moneda
      this.amount = parseFloat(this.amount).toFixed(2);
    },
    handleNext() {
      this.$emit("next", { amount: this.amount });
    },
  },
  computed: {
    handleDisabled() {
      return this.data.amount <= 0;
    },
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
