<template>
  <v-card class="pa-5">
    <v-row dense>
      <v-col cols="12" md="4">
        <v-select
          :label="$t('Transaction origin')"
          outlined
          dense
          clearable
          :items="
            is_active_shipments
              ? [
                  `${$t('By admin')}`,
                  `${$t('User amount')}`,
                  `${$t('Charge of this trip')}`,
                  `${$t('Card')}`,
                  `${$t('Charge of this parcel')}`,
                  `${$t('Pago móvil automatizado')}`,
                  `${$t('automated_zelle')}`,
                  `${$t('manual_top_up_request')}`,
                  `${$t('corporate_master_assign')}`,
                ]
              : [
                  `${$t('By admin')}`,
                  `${$t('User amount')}`,
                  `${$t('Charge of this trip')}`,
                  `${$t('Card')}`,
                  `${$t('Pago móvil automatizado')}`,
                  `${$t('automated_zelle')}`,
                  `${$t('manual_top_up_request')}`,
                  `${$t('corporate_master_assign')}`,
                ]
          "
          v-model="table.searchItem"
          :disabled="table.tableLoading"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$t('Date range')"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              clearable
              :append-icon="icons.mdiCalendar"
              :disabled="table.tableLoading"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            :max="computedMaxDate"
            @input="updateDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          block
          color="primary"
          @click="getCorporatePaymentsFilter() && getPendingManualPayments()"
          :disabled="table.tableLoading"
        >
          {{ $t("Apply") }}</v-btn
        ></v-col
      >
      <v-col cols="12" md="2" :class="!$vuetify.breakpoint.mdAndUp && 'mt-5'">
        <v-btn
          block
          outlined
          color="primary"
          @click="getCorporatePaymentsExcelFilter()"
          :disabled="table.tableLoading"
        >
          <v-icon> {{ icons.mdiDownload }}</v-icon>
          <span class="ml-2">{{ $t("Export") }}</span>
        </v-btn></v-col
      >
    </v-row>
    <!-- tabla -->
    <v-row dense >
      <v-col cols="12">
        <DataTable
          :loading="table.tableLoading"
          :headers="table.headers"
          :items="table.movements"
          :page="table.currentPage"
          v-if="!new_"
          @refresh="handleRefreshTables()"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      v-if="!new_"
      class="d-flex flex-row justify-space-between align-center"
    >
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-row justify-end align-center pt-8"
      >
        <v-select
          :label="$t('Results per page')"
          :items="[5, 10, 20, 30]"
          v-model="table.limit"
          :disabled="table.tableLoading"
          dense
          outlined
        >
        </v-select>
        <v-autocomplete
          :class="!$vuetify.mdAndUp && 'ml-2'"
          :label="$t('Go to page')"
          :items="Array.from(Array(table.pages + 1).keys()).slice(1)"
          v-model="table.currentPage"
          :disabled="table.tableLoading"
          dense
          outlined
          @input="table.currentPage && handlePageChange()"
        >
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-row justify-md-end justify-center align-start"
      >
        <!-- <code>currentPage: {{ table.currentPage }}</code>
              <code>Pages: {{ table.pages }}</code>
              <code>ComputedPage: {{ computedPage }}</code> -->
        <v-pagination
          v-model="table.currentPage"
          :length="table.pages"
          total-visible="12"
          @input="handlePageChange()"
          :disabled="table.tableLoading"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DataTable from "./DataTable.vue";
import { mapState } from "vuex";

export default {
  name: "PaymentTableTab",
  components: {
    DataTable,
  },
  props: {
    table: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    new_: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      date: [],
    };
  },
  methods: {
    handlePageChange() {
      this.$emit("pageChange");
    },
    handleRefreshTables() {
      this.$emit("refreshTables");
    },
    getCorporatePaymentsFilter() {
      this.$emit("getCorporatePaymentsFilter");
    },
    getPendingManualPayments() {
      this.$emit("getPendingManualPayments");
    },
    getCorporatePaymentsExcelFilter() {
      this.$emit("getCorporatePaymentsExcelFilter");
    },
    updateDate(newDate) {
      this.$emit("updateDate", newDate);
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft", "is_active_shipments"]),
    computedMaxDate() {
      let today = new Date();
      // utc-4 ccs
      return this.$moment(today).add(-4, "hours").toISOString();
    },
  },
};
</script>
