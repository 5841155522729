<template>
  <div class="d-flex flex-column justify-between">
    <ValidationObserver v-slot="{ invalid }">
      <v-card class="pt-8 pb-8" elevation="0">
        <div class="text--primary flex-grow-1">
          <p class="text--primary text-center text-xl font-weight-medium">
            {{ $t("Amount to pay") }}
          </p>
          <p
            v-if="currency_rate"
            class="text--green text-center text-3xl font-weight-bold"
          >
            {{
              computedPrice
                | currency({
                  symbol: "Bs ",
                  thousandsSeparator: ",",
                  fractionCount: 2,
                  fractionSeparator: ".",
                  symbolPosition: "front",
                  symbolSpacing: false,
                  avoidEmptyDecimals: undefined,
                })
            }}
          </p>

          <v-divider class="my-4"></v-divider>

          <p class="font-weight-bold text-center">
            {{ $t("Make the payment to") }}:
          </p>

          <v-row class="bg-light-gray my-1">
            <v-col cols="4" class="pa-1 px-4"> {{ $t("Bank") }} </v-col>
            <v-col cols="8" class="text-end pa-1 font-weight-bold">
              {{ bank.name }}
              <span class="ml-2">
                <v-tooltip top v-model="showTooltip.showTooltipNameNumber">
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      @click="
                        handleCopyClipboard(bank.name, 'showTooltipNameNumber')
                      "
                      small
                    >
                      <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Bank copied!") }}</span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <v-row class="bg-light-gray my-1">
            <v-col cols="4" class="pa-1 px-4"> RIF </v-col>
            <v-col cols="8" class="text-end pa-1 font-weight-bold">
              {{ bank.rif }}
              <span class="ml-2">
                <v-tooltip top v-model="showTooltip.showTooltipRIFHolder">
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      @click="
                        handleCopyClipboard(bank.rif, 'showTooltipRIFHolder')
                      "
                      small
                    >
                      <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("RIF copied!") }}</span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <v-row class="bg-light-gray my-1">
            <v-col cols="5" class="pa-1 px-4"> {{ $t("Phone number") }} </v-col>
            <v-col cols="7" class="text-end pa-1 font-weight-bold">
              {{ bank.number }}
              <span class="ml-2">
                <v-tooltip top v-model="showTooltip.showTooltipNumberHolder">
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      @click="
                        handleCopyClipboard(
                          bank.number,
                          'showTooltipNumberHolder'
                        )
                      "
                      small
                    >
                      <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Phone number copied!") }}</span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>
          <p class="text-xl font-weight-bold">
            {{ $t("Enter the details of the pago móvil made") }}
          </p>

          <v-row dense class="">
            <v-col cols="12">
              <ValidationProvider
                :name="$t('Bank')"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-autocomplete
                  v-model="pagoMovilForm.bank"
                  :items="bankList"
                  item-value="code"
                  :menu-props="{ contentClass: 'list-style' }"
                  dense
                  outlined
                  :label="$t('Bank')"
                  :error-messages="errors[0] && errors[0]"
                  :filter="handleFilterAutocompleteBanks"
                >
                  <template v-slot:selection="{ item }">
                    <span
                      :style="
                        (item.code + item.name).length > 35 &&
                        'font-size: 0.6rem;'
                      "
                    >
                      {{ item.code }} - {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.code }} - {{ item.name }}
                  </template>
                </v-autocomplete>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col cols="12" md="4">
              <ValidationProvider
                :name="$t('Operator')"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-select
                  :items="pagoMovilForm.phonePrefixList"
                  :label="$t('Operator')"
                  outlined
                  dense
                  v-model="pagoMovilForm.phonePrefix"
                  :error-messages="errors[0] && errors[0]"
                >
                </v-select>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="8">
              <ValidationProvider
                :name="$t('Phone')"
                rules="required|min:7|max:7|numeric"
                v-slot="{ errors }"
                slim
              >
                <PhoneInput
                  :label="$t('Phone')"
                  v-model="pagoMovilForm.phone"
                  dense
                  outlined
                  :long="false"
                  :error-messages="errors[0] && errors[0]"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col cols="12" md="6">
              <ValidationProvider
                :name="$t('Payment date')"
                rules="required"
                v-slot="{ errors }"
              >
                <DatePickerInput
                  :label="$t('Payment date')"
                  placeholder="Ingrese su Fecha de pago"
                  v-model="pagoMovilForm.date"
                  outlined
                  dense
                  :max="maxDay"
                  :error-messages="errors[0] && errors[0]"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                :name="$t('Last 4 reference digits')"
                rules="required|min:4|max:4|numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="$t('Last 4 reference digits')"
                  v-model="pagoMovilForm.digits"
                  outlined
                  dense
                  v-mask="'####'"
                  :hint="$t('Last 4 reference digits')"
                  persistent-hint
                  :error-messages="errors[0] && errors[0]"
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>
        </div>

        <!-- Botones para volver y continuar -->
        <div class="button-group">
          <v-btn rounded outlined style="width: 40%" @click="$emit('prev')">
            {{ $t("Back") }}
          </v-btn>
          <v-btn
            rounded
            style="width: 40%"
            color="primary"
            :disabled="invalid"
            :loading="loading"
            @click="$handlePagoMovil"
          >
            {{ $t("Continue") }}
          </v-btn>
        </div>
      </v-card>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiAlert,
  mdiContentCopy,
  mdiInformationOutline,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { copyTextToClipboard } from "@/mixins/copyToClipboardMixin";
import bankList from "@/api/bankList";
import PhoneInput from "@/components/PhoneInput";
import DatePickerInput from "@/components/DatePickerInput";
import { mask } from "vue-the-mask";

export default {
  name: "PagoMovilStep2",
  mixins: [copyTextToClipboard],
  directives: { mask },
  components: {
    PhoneInput,
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currency_rate: null,
      icons: {
        mdiMagnify,
        mdiAlert,
        mdiContentCopy,
        mdiInformationOutline,
      },
      bank: {
        number: "04126509977",
        rif: "J-500969210",
        name: "0172 - Bancamiga",
      },

      showTooltip: {
        showTooltipNumberHolder: false,
        showTooltipRIFHolder: false,
        showTooltipNameNumber: false,
      },
      bankList: bankList,
      pagoMovilForm: {
        bank: "",
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: "",
        phone: "",
        date: "",
        digits: "",
        customInvoice: undefined,
      },
      loading: false,
    };
  },
  methods: {
    handleCopyClipboard(value, tooltip = null) {
      this.copyTextToClipboard(value);
      if (tooltip && !this.showTooltip[tooltip]) {
        this.showTooltip[tooltip] = true;
        setTimeout(() => {
          this.showTooltip[tooltip] = false;
        }, 2000);
      }
    },
    handleFilterAutocompleteBanks(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const name = _item.name.toLowerCase();
      const code = _item.code.toLowerCase();

      return name.indexOf(searchText) > -1 || code.indexOf(searchText) > -1;
    },
    async $handlePagoMovil() {
      try {
        const params = {
          phone: `58${this.pagoMovilForm.phonePrefix.slice(1)}${
            this.pagoMovilForm.phone
          }`,
          originBank: this.pagoMovilForm.bank,
          date: this.pagoMovilForm.date,
          reference: `${this.pagoMovilForm.digits}`,
          customInvoice: this.pagoMovilForm.customInvoice,
          proforma:
            this.pagoMovilForm.proforma && this.pagoMovilForm.proformaNumber,
          proformaNumber: this.pagoMovilForm.proformaNumber,
        };
        this.loading = true;
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/add-pago-movil`,
          params
        );
        this.data.success = data.success;
        this.data.currentBalance = data.data.total_wallet_amount;
        this.data.rechargeAmount = data.data.wallet_amount;
        this.data.rechargeAmountBs = data.data.wallet_amount_bs;
      } catch (error) {
        this.data.success = false;
        console.error(error);
      } finally {
        this.loading = false;
        this.$emit("next");
      }
    },
  },
  computed: {
    maxDay() {
      let today = new Date();
      return this.$moment(today).add(-4, "hours").toISOString();
    },
    computedPrice() {
      return (
        parseFloat(this.data.amount.replace(/,/g, "")) * this.currency_rate
      );
    },
  },
  async beforeMount() {
    const { data } = await axios.get("/get_currency_rate");
    if (data.success) {
      this.currency_rate = data.currency_rate;
    } else {
      throw new Error("Error getting Currency Rate");
    }
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.text--green {
  color: #2bd4aa !important;
}
.bg-light-gray {
  background-color: #fafafa !important;
}
</style>
