<template>
  <v-row >
    <v-col cols="12">
      <v-card class="pa-5">
        <v-row dense>
          <v-col cols="9" class="d-flex flex-row align-center">
            <h2 :style="!$vuetify.breakpoint.mdAndUp && 'font-size: 0.8rem;'">
              {{ $t("manual_top_up_pending_table") }}
              {{ ` (${pendingValidationTable.total})` }}
            </h2>
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  medium
                  v-on="on"
                  class="ml-6 color-gray-text"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>{{ $t("manual_top_up_pending_table_tooltip") }}</span>
            </v-tooltip>
          </v-col>
          <!-- <v-col
                        cols="3"
                        class="d-flex flex-row justify-end"
                      >
                        <v-btn
                          icon
                          @click="
                            pendingManualTopUpsHiddenTable =
                              !pendingManualTopUpsHiddenTable
                          "
                          class="mb-1"
                        >
                          <v-icon large>
                            {{
                              pendingManualTopUpsHiddenTable
                                ? icons.mdiToggleSwitchOffOutline
                                : icons.mdiToggleSwitchOutline
                            }}
                          </v-icon>
                        </v-btn>
                      </v-col> -->
        </v-row>
        <v-row dense class="mt-4">
          <v-col cols="12">
            <DataTablePendingManualPayments
              :loading="pendingValidationTable.loading"
              :headers="pendingValidationTable.headers"
              :items="pendingValidationTable.items"
              :page="pendingValidationTable.currentPage"
              @refresh="handleRefreshTables()"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="!new_"
          class="d-flex flex-row justify-space-between align-center"
        >
          <v-col
            cols="12"
            md="4"
            class="d-flex flex-row justify-end align-center pt-8"
          >
            <v-select
              :label="$t('Results per page')"
              :items="[5, 10, 20, 30]"
              v-model="pendingValidationTable.limit"
              :disabled="pendingValidationTable.loading"
              dense
              outlined
            >
            </v-select>
            <v-autocomplete
              :class="!$vuetify.mdAndUp && 'ml-2'"
              :label="$t('Go to page')"
              :items="
                Array.from(
                  Array(pendingValidationTable.pages + 1).keys()
                ).slice(1)
              "
              v-model="pendingValidationTable.currentPage"
              :disabled="pendingValidationTable.loading"
              dense
              outlined
              @input="
                pendingValidationTable.currentPage &&
                  handlePendingTablePageChange()
              "
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-row justify-md-end justify-center align-start"
            ><v-pagination
              v-model="pendingValidationTable.currentPage"
              :length="pendingValidationTable.pages"
              total-visible="12"
              @input="handlePendingTablePageChange()"
              :disabled="pendingValidationTable.loading"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTablePendingManualPayments from "./DataTablePendingManualPayments.vue";

export default {
  name: "ManualTopUpsTableTab",
  components: {
    DataTablePendingManualPayments,
  },
  props: {
    pendingValidationTable: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    new_: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    computedTablePendingManualPayments() {
      return this.pendingValidationTable.movements;
    },
  },
  methods: {
    handleRefreshTables() {
      this.$emit("refreshTables");
    },
    handlePendingTablePageChange() {
      this.$emit("pendingTablePageChange");
    },
  },
};
</script>
