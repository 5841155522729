<template>
  <PaymentStepper
    :steps="steps"
    :title="data.name"
    @close="close"
    @closePaymentDrawer="$emit('closePaymentDrawer')"
    @refresh="$emit('refresh')"
  >
    <template v-slot:image>
      <div style="min-height: 9vh">
        <v-img class="mr-4" v-if="data.svgIcon" :src="data.svgIcon" />
      </div>
    </template>
  </PaymentStepper>
</template>

<script>
import PaymentStepper from "../PaymentStepper.vue";
import AmountStep from "../AmountStep.vue";
import ManualPaymentStep2 from "./ManualPaymentStep2.vue";
import ManualPaymentStep3 from "./ManualPaymentStep3.vue";
import ResultStep from "../ResultStep.vue";

export default {
  components: {
    PaymentStepper,
    AmountStep,
    ManualPaymentStep2,
    ManualPaymentStep3,
    ResultStep,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentData: {
        amount: 0,
        success: false,
        waiting: false,
        PaymentTitle: this.data.name,
        ...this.data,
      },
      steps: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setAmount(amount) {
      this.data.amount = amount;
      this.steps[1].data.amount = amount;
    },
  },
  created() {
    this.steps = [];
    this.paymentData.dataStep = 2;
    if (this.data.currency === "bs") {
      this.paymentData.dataStep = 3;
      this.steps.push({
        component: AmountStep,
        data: this.paymentData,
      });
    }

    this.steps.push(
      {
        component: ManualPaymentStep2,
        data: this.paymentData,
      },
      {
        component: ManualPaymentStep3,
        data: this.paymentData,
      },
      {
        component: ResultStep,
        data: this.paymentData,
      }
    );
  },
};
</script>
