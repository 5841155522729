var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-between"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"pt-8 pb-8",attrs:{"elevation":"0"}},[_c('div',{staticClass:"text--primary flex-grow-1"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "Send your payment receipt and related information to process the transaction" ))+" ")]),_c('v-divider',{staticClass:"my-4"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Reference'),"rules":"required|max:200","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Reference'),"dense":"","outlined":"","error-messages":errors[0] && errors[0],"clearable":""},model:{value:(_vm.manualTopUpForm.details.reference),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "reference", $$v)},expression:"manualTopUpForm.details.reference"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":_vm.$t('Payment date'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePickerInput',{attrs:{"label":_vm.$t('Payment date'),"placeholder":_vm.$t('Enter your payment date'),"outlined":"","dense":"","max":_vm.maxDay,"error-messages":errors[0] && errors[0]},model:{value:(_vm.manualTopUpForm.details.date),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "date", $$v)},expression:"manualTopUpForm.details.date"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":_vm.$t('Email to send invoice'),"rules":"required|email|max:200","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Email to send invoice'),"dense":"","outlined":"","error-messages":errors[0] && errors[0],"clearable":""},model:{value:(_vm.manualTopUpForm.details.email),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "email", $$v)},expression:"manualTopUpForm.details.email"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-2 d-flex justify-center align-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.data.currency === 'usd')?_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],staticClass:"pa-2",attrs:{"label":_vm.$t('Amount to add'),"prefix":"$","outlined":"","dense":"","validate-on-blur":"","rules":[
                function (v) { return !!v || _vm.$t('Amount is required'); },
                function (v) {
                  var numericValue =
                    typeof v === 'number'
                      ? v
                      : parseFloat(_vm.data.amount.replace(/,/g, ''));
                  return (
                    numericValue > 0 || _vm.$t('Amount must be greater than 0')
                  );
                } ]},model:{value:(_vm.data.amount),callback:function ($$v) {_vm.$set(_vm.data, "amount", $$v)},expression:"data.amount"}}):_vm._e()],1)],1),_c('v-row',{staticClass:"mt-2 d-flex justify-center align-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Payment receipt'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-file-input',{ref:"file",attrs:{"show-size":"","truncate-length":_vm.$vuetify.breakpoint.mdAndUp ? 50 : 10,"label":_vm.$t('Payment receipt'),"error-messages":errors[0] && errors[0],"accept":"image/png, image/jpg, image/jpeg","dense":"","outlined":""},on:{"change":function($event){return _vm.previewImage()}},model:{value:(_vm.manualTopUpForm.details.paymentImage),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "paymentImage", $$v)},expression:"manualTopUpForm.details.paymentImage"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Do you want a custom invoice?'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":_vm.$t('Do you want a custom invoice?'),"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Do you want a custom invoice?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.manualTopUpForm.customInvoice),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm, "customInvoice", $$v)},expression:"manualTopUpForm.customInvoice"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Yes"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{staticClass:"text--primary",attrs:{"label":_vm.$t('No'),"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Is this top up related to a proform?'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":_vm.$t('Is this top up related to a proform?'),"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Is this top up related to a proform?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.manualTopUpForm.proforma),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm, "proforma", $$v)},expression:"manualTopUpForm.proforma"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Yes"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"label":_vm.$t('No'),"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}],null,true)}),(_vm.manualTopUpForm.proforma)?_c('ValidationProvider',{attrs:{"name":_vm.$t('Proforma number'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Proforma'),"outlined":"","dense":"","error-messages":errors[0] && errors[0]},model:{value:(_vm.manualTopUpForm.proformaNumber),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm, "proformaNumber", $$v)},expression:"manualTopUpForm.proformaNumber"}})]}}],null,true)}):_vm._e()],1)],1)],1),_c('div',{staticClass:"button-group"},[_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]),_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","color":"primary","loading":_vm.loading,"disabled":invalid || (_vm.data.currency === 'usd' && _vm.data.amount <= 0)},on:{"click":_vm.handleNext}},[_vm._v(" "+_vm._s(_vm.$t("Continue"))+" ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }